@font-face {
    font-family: "OpenSans";
    src: url('./OpenSans-Regular.ttf');
    font-weight: 400;
    font-style: normal;
}

@font-face {
    font-family: "OpenSans";
    src: url('./OpenSans-Bold.ttf');
    font-weight: 800;
    font-style: normal;
}

@font-face {
    font-family: "OpenSans";
    src: url('./OpenSans-SemiBold.ttf');
    font-weight: 600;
    font-style: normal;
}
  