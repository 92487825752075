@import url("../fonts/index.css");

body {
  margin: 0;
  font-family: 'OpenSans';
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

.section-title {
  font-size: 18pt;
  font-weight: 800;
  color: #1976d2;
}

.default-input {
  width: 180px;
  height: 40px;
}

.table-input {
  width: 90px;
  height: 40px;
}

.simple-border {
  border: 1px solid #000000;
}

body::-webkit-scrollbar {
  width: 5em;
}
 
body::-webkit-scrollbar-track {
  box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.3);
}
 
body::-webkit-scrollbar-thumb {
  background-color: darkgrey;
  outline: 1px solid slategrey;
}